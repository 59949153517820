import React, { useEffect, useRef } from "react";

const FloatingBanner = ({ isSticky = false }) => {
  const bannerRef = useRef(null);

  useEffect(() => {
    let curScroll;
    let prevScroll = window.scrollY || window.scrollTop;
    let curDirection = 0;
    let prevDirection = 0;
    const threshold = 200;

    const checkScroll = () => {
      curScroll = window.scrollY || window.scrollTop;
      curDirection = curScroll > prevScroll ? 2 : 1;

      if (curDirection !== prevDirection) {
        moveFloatingBanner();
      }

      prevScroll = curScroll;
    };

    const moveFloatingBanner = () => {
      if (curDirection === 2 && curScroll > threshold) {
        bannerRef.current.classList.add("floating-banner--top");
        prevDirection = curDirection;
      } else if (curDirection === 1) {
        bannerRef.current.classList.remove("floating-banner--top");
        prevDirection = curDirection;
      }
    };

    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <div ref={bannerRef} className={`${isSticky ? "floating-banner--sticky" : ""} floating-banner`}>
      <p>
        With effect from 16th June 2024, our Terms and Conditions have been updated.
        <br />
        If an amendment to a Purchase or Remortgage application that has reached formal offer is requested after 16th
        June, an updated mortgage deed signed by the customer(s) will be required by the acting Solicitor / Conveyancer.
        <br />
        Submitted applications that require an amendment prior to this date will not be impacted by the above change.
      </p>
    </div>
  );
};

export default FloatingBanner;
